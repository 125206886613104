.body {
  background: rgb(10,19,40);
  background: linear-gradient(180deg, rgba(10,19,40,1) 0%, rgba(132,188,222,1) 100%);
}

.App {
  text-align: center;
  background: rgb(10,19,40);
background: linear-gradient(180deg, rgba(10,19,40,1) 0%, rgba(132,188,222,1) 100%);
-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
-moz-animation: fadein 2s; /* Firefox < 16 */
 -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
     animation: fadein 2s;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background: rgb(62,129,163);
  background: radial-gradient(circle, rgba(62,129,163,1) 0%, rgba(132,188,222,1) 100%);  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.clues {
  display: flex;
  flex-direction: row;
  /* height: 20%; */
}

.clue {
  width: 24%;
  height: 24vh;
  margin: 10px;
  overflow: hidden;
  border-radius: 24px;
  padding: 4px;
  background: rgb(221,242,254);
  background: linear-gradient(172deg, rgba(221,242,254,1) 0%, rgba(132,188,222,1) 100%);
  align-content: center;
  justify-content: center;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
-moz-animation: fadein 2s; /* Firefox < 16 */
 -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
     animation: fadein 2s;

}

.clue-text {
  width: 24%;
  height: 24vh;
  margin: 10px;
  overflow: hidden;
  border-radius: 24px;
  padding: 4px;
  background: rgb(221,242,254);
  background: linear-gradient(172deg, rgba(221,242,254,1) 0%, rgba(132,188,222,1) 100%);
  align-content: center;
  justify-content: center;
  line-height: 22vh;
  /* padding-top: 40px; */
}


.clue-text span {
  margin-top: 40px;
  padding-top: 40%;
  font-weight: 500;
  font-size: 20px;
  justify-self: center;
  align-self: center;
  text-align:center;
  vertical-align:middle;
}

.clue img {
  object-fit: fill;
  height: 100%;
  width: 100%;
  border-radius: 24px;
}

.prompt {
  background: rgb(13,32,73);
background: linear-gradient(180deg, rgba(13,32,73,1) 0%, rgba(18,67,97,1) 100%);
width: 100%;
}





@keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}